.tv-pulse-btn {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: block;
  width: 50px;
  height: 50px;
  line-height:50px;
  text-align:center;
  color: #fff;
  border: none;
  box-shadow: 0 0 0 0 #f0f0f0, 0 0 0 0 rgba(241, 37, 0, 0.7);
  border-radius: 25px;
  v-align: middle;
  background: #e92107;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0.33, 0, 1);
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 12px transparent, 0 0 0 24px rgba(227, 115, 14, 0);
  }
}