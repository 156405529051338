@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.right {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}
.left {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.tv-pulse-btn {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: block;
  width: 50px;
  height: 50px;
  line-height:50px;
  text-align:center;
  color: #fff;
  border: none;
  box-shadow: 0 0 0 0 #f0f0f0, 0 0 0 0 rgba(241, 37, 0, 0.7);
  border-radius: 25px;
  v-align: middle;
  background: #e92107;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0.33, 0, 1);
          animation: pulse 1.25s infinite cubic-bezier(0.66, 0.33, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 12px transparent, 0 0 0 24px rgba(227, 115, 14, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 12px transparent, 0 0 0 24px rgba(227, 115, 14, 0);
  }
}

html, body {
  /* background-color: #f1f2f5; */
  height:100%;
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

